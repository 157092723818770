import Joi from 'joi';

import {logger as baseLogger} from 'shared/utils/logger';

import type {
  AdCreativeConfig,
  AdCreativeTypeName,
  DeeplinkParams,
  ExtResponseAdConfig,
  OpenUrlParams,
  QrParams,
  QuarkUrlParams,
} from 'features/adoppler';

const logger = baseLogger.child({tag: '[Ext Parsing Validator]'});

const qrParamsSchema = Joi.object<QrParams>({
  url: Joi.string().uri().required(),
});

const openUrlParamsSchema = Joi.object<OpenUrlParams>({
  trigger: Joi.string().valid('a_button', 'b_button').required(),
  screen: Joi.string().valid('SMART', 'THEATER').required(),
  url: Joi.string().uri().required(),
});

const deeplinkParamsSchema = Joi.object<DeeplinkParams>({
  trigger: Joi.string().valid('a_button', 'b_button').required(),
  screen: Joi.string().valid('SMART', 'THEATER').required(),
  url: Joi.string().uri().required(),
  package: Joi.string().required(),
  market: Joi.string().required(),
});

const quarkParamsSchema = Joi.object<QuarkUrlParams>({
  trigger: Joi.string().valid('a_button', 'b_button').required(),
  display: Joi.string().valid('SMART', 'THEATER').required(),
  url: Joi.string().uri().required(),
  skipReady: Joi.boolean().required(),
  templateId: Joi.string().valid(
    'TheaterScreen_Fullscreen_App',
    'SmartScreen_Fullscreen_App',
    'SmartScreen_App_With_Ad',
    'DualScreen_Fullscreen_App',
    'DualScreen_App_With_Ad',
  ).required(),
});

const baseActionSchema = (type: string, paramsSchema: Joi.ObjectSchema) => Joi.object({
  type: Joi.string().valid(type).required(),
  params: paramsSchema.required(),
});

const actionSchema = Joi.alternatives().try(
  baseActionSchema('qr', qrParamsSchema),
  baseActionSchema('open_url', openUrlParamsSchema),
  baseActionSchema('deeplink', deeplinkParamsSchema),
  baseActionSchema('quark_url', quarkParamsSchema),
);

const adCreativeConfigSchema = Joi.object({
  ad_creative_type_name: Joi.string<AdCreativeTypeName>().valid(
    'eve_expandable_ad_full',
    'eve_expandable_ad_qab',
    'eve_expandable_ad_qrcode',
    'eve_expandable_ad_emptyadpanel',
    'eve_house_ad_full',
    'eve_house_ad_qab',
    'eve_house_ad_qrcode',
    'eve_house_ad_emptyadpanel',
  ),
  ad_component_type: Joi.string<AdCreativeConfig['ad_component_type']>()
    .valid('full', 'qab', 'qrcode', 'empty_ad_panel'),
  ad_conversion_trigger: Joi.string<AdCreativeConfig['ad_conversion_trigger']>()
    .valid('none', 'a_button', 'b_button'),
  ad_conversion_target_display: Joi.string<AdCreativeConfig['ad_conversion_target_display']>()
    .valid('none', 'SMART', 'THEATER'),
  action: actionSchema,
  version: Joi.string(),
});

const creativeTypeSchema = Joi.object({
  creative_type: adCreativeConfigSchema,
});

const tellySchema = Joi.object({
  telly: adCreativeConfigSchema,
});

/**
 * Validates the structure of an Ad Click Tracker impression using Joi.
 *
 * @param {ExtResponseAdConfig} ext - The Ad Click Tracker impression to validate.
 * @return {Joi.ValidationResult<ExtResponseAdConfig>} - The result of the validation.
 */
function validateExt(
  ext: ExtResponseAdConfig,
): Joi.ValidationResult<ExtResponseAdConfig> {
  let validationResult = Joi.object({}).validate({}, {abortEarly: false});

  if ('telly' in ext) {
    validationResult = tellySchema.validate(ext, {abortEarly: false});
  } else if ('creative_type' in ext) {
    validationResult = creativeTypeSchema.validate(ext, {abortEarly: false});
  }

  const {error} = validationResult;

  if (error) {
    logger.warn('Ext Parsing validator warning', {error});
  }

  return validationResult;
}

export default validateExt;
