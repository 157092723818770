import axios, {isCancel} from 'axios';

import {isIPv4, isIPv6} from 'shared/utils';
import {logger as baseLogger} from 'shared/utils/logger';

import type {AxiosResponse} from 'axios';
import type {DeviceIpAddresses} from 'shared/utils';

const logger = baseLogger.child({tag: '[IP Service]'});

/**
 * Retrieves both IPv4 and IPv6 addresses from external APIs.
 *
 * @return {Promise<DeviceIpAddresses>} A promise that resolves to an object containing IPv4 and/or IPv6 addresses.
 * @throws {Error} If no valid IP addresses are available.
 */
export const getIpAddresses = async (): Promise<DeviceIpAddresses> => {
  try {
    const ipv4 = await getIp('https://api.ipify.org/?format=json');
    const ipv6 = await getIp('https://api64.ipify.org/?format=json');

    return {
      ip: ipv4 && isIPv4(ipv4) ? ipv4 : undefined,
      ipv6: ipv6 && isIPv6(ipv6) ? ipv6 : undefined,
    };
  } catch (error) {
    logger.error(`Failed to fetch, Error: ${(error as Error).message}`);
    return {ip: undefined, ipv6: undefined};
  }
};

/**
   * Fetches an IP address from a given URL.
   *
   * @param {string} url - The URL to fetch the IP address from.
   * @return {Promise<string | undefined>} A promise that resolves to the fetched IP address or undefined if fetching fails.
   */
export const getIp = async (url: string): Promise<string | undefined> => {
  try {
    const response: AxiosResponse<DeviceIpAddresses> = await axios.get(url, {timeout: 3000});

    // Check if the response status is OK
    if (response.status !== 200) {
      logger.info(`Received non-OK status code from service: ${url}`);
      return undefined;
    }

    const {data} = response;

    // Check if the IP address is present in the response
    if (!data.ip) {
      logger.info(`IP address not found in response from service: ${url}`);
      return undefined;
    }

    return data.ip;
  } catch (error) {
    if (isCancel(error)) {
      logger.error(`Request to service: ${url} timed out`);
    } else {
      logger.error(`Can't receive IP from service: ${url}, Error: ${(error as Error).message}`);
    }
  }
};
