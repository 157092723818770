import {useEffect, useState} from 'react';

import {meteEnvConfig} from 'config';
import {useAdUnitConfigContext, useAppContext} from 'context';
import freestarManager from 'shared/utils/freestar-ads';
import {logger as baseLogger} from 'shared/utils/logger';

import {getGoogleConfigData, getKeyByValue} from './helpers';

import type {SizeChangeEvent, UseFreestarReturnType} from './types';

const logger = baseLogger.child({tag: '[Freestar slot]'});

/**
 * Creates a Freestar slot with the specified size and adds it to the page.
 * @return {UseFreestarReturnType}
 */
export function useFreestar(): UseFreestarReturnType {
  const isFreeStarEnabled = ['EVE_FULLSCREEN_UNIT', '3P_SMART_SCREEN_AD_UNIT'].includes(meteEnvConfig.ads.adUnit);

  const {deviceProperties} = useAppContext();
  const {adUnitConfig} = useAdUnitConfigContext();

  const featureFlags = adUnitConfig.features;
  const {slotId: googleSlotId, targeting} = getGoogleConfigData(adUnitConfig);

  const [renderedSize, setRenderedSize] = useState<string | number[] | null>(null);

  logger.info('Render Freestar component', deviceProperties);

  const handleSizeChange = (event: CustomEvent<SizeChangeEvent>) => {
    setRenderedSize(event.detail.size);
  };

  useEffect(() => {
    if (!deviceProperties || !isFreeStarEnabled) return;

    const targetingProfiles = targeting && Object.keys(targeting).map((key)=> {
      return {key, value: targeting[key]};
    });
    const hashedEmail = deviceProperties.user_identifiers?.uid2;
    const coppa = deviceProperties.coppa;
    const slots = [
      {
        placementName: `freetelly_300x250${meteEnvConfig.environment !== 'production' ? '_test' : ''}`,
        slotId: 'native-ad-fs',
        targeting: [{
          key: 'ifa',
          value: deviceProperties.ad_info.ifa,
        },
        ...(targetingProfiles?.length ? targetingProfiles : []),
        ],
      },
    ];

    freestarManager.addHashedEmailAndSlots(slots, hashedEmail, deviceProperties, coppa);
    freestarManager.addSizeChangedEventListener(handleSizeChange);

    return () => {
      logger.info('Fallback Ad Load Timeout Canceled');
      freestarManager.removeSizeChangedEventListener(handleSizeChange);
      freestarManager.destroy();
    };
  }, [deviceProperties, googleSlotId, targeting, isFreeStarEnabled]);

  return {
    size: getKeyByValue(renderedSize),
    defaultQabElement: featureFlags?.showButtonB?.enable ? 'button-b' : 'default-ad',
    crid: freestarManager.getCreativeId(),
  };
}
