import {useEffect, useState} from 'react';

import {TELLY_EVENT} from 'shared/constants';
import {logger} from 'shared/utils';

type CustomEventHandlerReturnType<T> = T | null

/**
 * Custom hook to handle events and extract properties of type T from them.
 *
 * @template T - The type of properties to extract from the event.
 * @param {string} eventType The type of event. Default TELLY_EVENT
 * @return {{ eventDetail:  CustomEventHandlerReturnType<T>}} - An object containing the extracted properties of type T, or null if no event has been received yet.
 */
function useCustomEventHandler<T>(eventType: string = TELLY_EVENT): {eventDetail: CustomEventHandlerReturnType<T>} {
  const [eventDetail, setEventDetail] = useState<T | null>(null);

  useEffect(() => {
    const handleEvent = (event: Event): void => {
      logger.info('Got event from SDK:', {eventType, event});

      const properties = (event as unknown as {detail: T}).detail;
      setEventDetail(properties);
    };
    window.addEventListener(eventType, handleEvent);

    return () => window.removeEventListener(eventType, handleEvent);
  }, [eventType]);

  return {eventDetail};
}

export default useCustomEventHandler;
