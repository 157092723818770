/* eslint-disable camelcase */
import type {ReactNode, RefObject} from 'react';

import useAdClick from './useAdClick';
import V1QuickActionButtonRender from './V1QuickActionButtonRender';
import V2QuickActionButtonRender from './V2QuickActionButtonRender';

import type {QabElement, ExtResponseAdConfig} from 'features/adoppler';
import type {NativeAd} from 'features/adoppler/types/native';

import './styles.css';

interface Props {
  adClickTracker: NativeAd.Link | null;
  ext: ExtResponseAdConfig | undefined;
  defaultElement?: QabElement;
  crid?: string | undefined;
  displayControlsBlock?: boolean;
  adNode?: RefObject<HTMLElement>;
  isClickable?: boolean;
}

/**
 * ControlWrapper component wraps its children in a container with control elements.
 *
 * @component
 * @param {Props} props - The component's props.
 * @return {JSX.Element} The ControlWrapper component.
 */
function QuickActionButton(props: Props): ReactNode | null {
  const {ext, adClickTracker, defaultElement = 'default-ad', crid, adNode, isClickable} = props;

  const {url: clickUrl, clicktrackers: clickTrackers=[]} = adClickTracker ?? {};

  const {creative_type} = ext ?? {};
  const {version} = creative_type ?? {};

  const isV2Permutation = !!version || version?.startsWith('2');

  useAdClick({
    clickUrl,
    clickTrackers,
    crid,
    adNode,
    isClickable: isClickable || defaultElement === 'button-b',
    ext,
  });

  return isV2Permutation
  ? <V2QuickActionButtonRender {...props} />
  : <V1QuickActionButtonRender {...props} />;
}

export default QuickActionButton;
