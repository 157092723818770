
export type RetryPromiseParams<T> = {
  promiseFn: () => Promise<T>,
  isNeedRetry: (result: T) => boolean,
  timeout: number,
  maxRetries: number,
}

/**
 * Retries a promise function until it succeeds or the maximum number of retries is reached.
 *
 * @template T - The return type of the promise function.
 * @param {RetryPromiseParams<T>} params - The parameters for retrying the promise function.
 * @param {function(): Promise<T>} params.promiseFn - The promise function to be retried.
 * @param {function(T): boolean} params.isNeedRetry - A function that determines if the result of the promise function needs to be retried.
 * @param {number} params.timeout - The time (in milliseconds) to wait before retrying the promise function.
 * @param {number} params.maxRetries - The maximum number of times to retry the promise function.
 * @return {Promise<T>} - The eventual result of the retried promise function.
 */
export async function retryPromise<T>({
  promiseFn,
  isNeedRetry,
  timeout,
  maxRetries,
}: RetryPromiseParams<T>): Promise<T> {
  let retries = 0;
  return new Promise<T>((resolve, reject) => {
    const attempt = async () => {
      try {
        let result = await promiseFn();
        while (isNeedRetry(result) && retries < maxRetries) {
          await new Promise((r) => {
            setTimeout(r, timeout);
          });
          result = await promiseFn();
          retries++;
        }
        resolve(result);
        return;
      } catch (error) {
        reject(error);
        return; // Exit the loop on error
      }
    };

    attempt(); // Start the first attempt
  });
}
