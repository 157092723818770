import EventEmitter from 'events';

import axios from 'axios';

import {meteEnvConfig} from 'config';
import {logger as baseLogger} from 'shared/utils/logger';

import {baseUrl} from './constants';

import type {AxiosInstance} from 'axios';
import type {AdServer} from 'shared/utils/ad-analytics/types';

const logger = baseLogger.child({tag: '[metrics]'});

/**
 * Represents a dimension for a metric.
 */
interface Dimension {
  Name: string;
  Value: string;
}

type MetricName = keyof MetricOptions;

type MetricOptions = {
  adRequest: {
    adServer: AdServer
  }
  adImpression: {
    adServer: AdServer
  }
  Error: {
    message: string
  }
  adInteraction: never
}

/**
 * Represents a metric with its associated dimensions.
 */
interface Metric {
  MetricName: string;
  Dimensions: Dimension[];
  Value: number;
  Unit: string;
  Timestamp: string;
}

/**
 * Type for metric namespaces.
 */
type Namespace = 'Gabriel/Barker' | 'Gabriel/Google' | 'Gabriel/Elemental' | 'Gabriel/AdAnalytics'
  | 'Gabriel/Video' | 'Gabriel/Lifecycle' | 'Gabriel/Cache';

/**
 * Represents a collection of metrics under a specific namespace.
 */
interface MetricData {
  Namespace: Namespace;
  MetricData: Metric[];
}

const metricsSendInterval = 60000;

/**
 * Class for managing and sending metrics for a specific namespace.
 */
class MetricsService {
  private readonly namespace: Namespace;
  private metrics: Metric[] = [];
  private httpInstance: AxiosInstance;
  private eventEmitter: EventEmitter;

  /**
   * Creates an instance of MetricsService.
   * @param {Namespace} namespace - The namespace for the metrics service.
   */
  constructor(namespace: Namespace) {
    this.namespace = namespace;
    this.eventEmitter = new EventEmitter();
    this.httpInstance = axios.create({
      baseURL: baseUrl,
    });

    this.registerEmitter();
    this.startSendingMetrics();
  }

  /**
   * Proxy method to emit new Event
   * @param {string} metricName
   * @param {Dimension[]} options
   */
  public emitEvent(metricName: string, options?: Dimension[]): void {
    this.eventEmitter.emit(this.namespace, {...options, metricName});
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * Records a metric based on the metric type and options.
   * @template T - The type of the metric name.
   * @param {T} metricName - The name of the metric.
   * @param {MetricOptions[T]} [options] - The options to create the metric.
   * This parameter is optional for certain metric types.
   * @throws {Error} If the metric type is unknown.
   * @deprecated (?)
   */
  public recordMetric<T extends MetricName>(metricName: T, options?: MetricOptions[T]) {
    switch (metricName) {
      case 'adRequest':
        this.addMetric('adRequest', [
          {Name: 'Provider', Value: (<MetricOptions['adRequest']>options).adServer},
          {Name: 'Environment', Value: meteEnvConfig.environment},
          {Name: 'PlacementId', Value: meteEnvConfig.ads.adUnit},
          {Name: 'Version', Value: __APP_VERSION__},
        ]);
        break;
      case 'adImpression':
        this.addMetric('adImpression', [
          {Name: 'Provider', Value: (<MetricOptions['adImpression']>options).adServer},
          {Name: 'Environment', Value: meteEnvConfig.environment},
          {Name: 'PlacementId', Value: meteEnvConfig.ads.adUnit},
          {Name: 'Version', Value: __APP_VERSION__},
        ]);
        break;
      case 'adInteraction':
        this.addMetric('adInteraction', [
          {Name: 'Environment', Value: meteEnvConfig.environment},
          {Name: 'PlacementId', Value: meteEnvConfig.ads.adUnit},
          {Name: 'Version', Value: __APP_VERSION__},
        ]);
        break;
      case 'Error':
        this.addMetric('Error', [
          {Name: 'Message', Value: (<MetricOptions['Error']>options).message},
          {Name: 'Environment', Value: meteEnvConfig.environment},
          {Name: 'PlacementId', Value: meteEnvConfig.ads.adUnit},
          {Name: 'Version', Value: __APP_VERSION__},
        ]);
        break;
      default:
        throw new Error(`Unknown metric type: ${metricName}`);
    }
  }

  /**
   * Register event emitter
   * @private
   */
  private registerEmitter(): void {
    this.eventEmitter.on(this.namespace, (data) => {
      const {metricName, ...restData} = data;
      this.addMetric(metricName, this.getMetricData(Object.values(restData)) as Dimension[]);
    });
  }

  /**
     * Enrich received data with default values
     * @param {Dimension[]} restData
     * @return {Dimension[]}
     */
  private getMetricData(restData: Dimension[]): Dimension[] {
    return [
      {Name: 'Environment', Value: meteEnvConfig.environment},
      {Name: 'PlacementId', Value: meteEnvConfig.ads.adUnit},
      {Name: 'Version', Value: __APP_VERSION__},
      ...restData,
    ];
  }

  /**
     * Starts the interval for sending metrics.
     */
  private startSendingMetrics(): void {
    setInterval(() => this.sendMetrics(), metricsSendInterval);
  }

  /**
   * Adds a metric to the internal metric collection.
   * @param {string} metricName - The name of the metric.
   * @param {Dimension[]} dimensions - The dimensions associated with the metric.
   */
  private addMetric(metricName: string, dimensions: Dimension[]): void {
    this.metrics.push({
      MetricName: metricName,
      Dimensions: dimensions,
      Timestamp: new Date().toISOString(),
      Value: 1,
      Unit: 'Count',
    });
  }

  /**
   * Sends the collected metrics to the endpoint.
   */
  private async sendMetrics(): Promise<void> {
    if (this.metrics.length > 0) {
      const metricData: MetricData = {
        Namespace: this.namespace,
        MetricData: this.metrics,
      };

      try {
        await this.httpInstance.post('/metrics', metricData);
        if (__DEV__) {
          logger.debug('Sending metrics package', JSON.stringify(metricData, null, 2));
        }
      } catch (error) {
        logger.error('Failed to send metrics package', error);
      } finally {
        // Clear the metrics after attempting to send
        this.metrics = [];
      }
    }
  }
}

/**
 * Singleton class for managing and creating MetricsService instances.
 */
class MetricsSingleton {
  private static instance: MetricsSingleton;
  private services: Record<string, MetricsService> = {};

  /**
   * Returns the singleton instance of MetricsSingleton and the MetricsService for the specified namespace.
   * @param {Namespace} namespace - The namespace for the metrics service.
   * @return {MetricsService} The MetricsService instance.
   */
  public static getService(namespace: Namespace): MetricsService {
    if (!MetricsSingleton.instance) {
      MetricsSingleton.instance = new MetricsSingleton();
    }

    if (!MetricsSingleton.instance.services[namespace]) {
      MetricsSingleton.instance.services[namespace] = new MetricsService(namespace);
    }

    return MetricsSingleton.instance.services[namespace];
  }
}

/**
 * Uses in several places in the app.
 */
const gabrielLifecycleMetrics = MetricsSingleton.getService('Gabriel/Lifecycle');
const gabrielElementalMetrics = MetricsSingleton.getService('Gabriel/Elemental');
const gabrielCacheMetrics = MetricsSingleton.getService('Gabriel/Cache');

export {MetricsSingleton, MetricsService, gabrielElementalMetrics, gabrielLifecycleMetrics, gabrielCacheMetrics};
