import FetchWorker from 'shared/api/ad/adoppler/worker';

import type {WorkerConfig, WorkerConfigCache, WorkerParams} from 'types';

import type {AdTypes} from 'features/adoppler';

/**
 * Worker manager
 */
export class FetchWorkerManager {
  private pool: FetchWorker[] = [];

  /**
   * Run worker loop
   * @param {Array} items
   */
  constructor(items: {config: WorkerConfig, params: WorkerParams}[]) {
    items.forEach((item) => {
      const config = item.config;
      const worker = this.spawn(config.url, config.scope, config.cache, config.adType);
      worker.run(item.params);
      this.pool.push(worker);
    });
  }

  /**
   * Attach new worker in case it not existing yet
   * @param {WorkerConfig} config
   * @param {WorkerParams} params
   */
  attach(config: WorkerConfig, params: WorkerParams) {
    if (!this.hasWorkerRegistered(config.scope)) {
      const worker = this.spawn(config.url, config.scope, config.cache, config.adType);
      worker.run(params);
      this.pool.push(worker);
    }
  }

  /**
   * Check if scope worker exists
   * @param {string} workerScope
   * @return {boolean}
   */
  hasWorkerRegistered(workerScope: string): boolean {
    return this.pool.some((worker) => worker.config.scope === workerScope);
  }

  /**
   * Stop workers
   */
  terminate() {
    this.pool.forEach((worker) => {
      worker.terminate();
    });
  }

  /**
   * Spawn new worker
   * @param {string} url
   * @param {string} scope
   * @param {WorkerConfigCache} cache
   * @param {AdTypes} adType
   * @return {FetchWorker}
   */
  spawn(url: string, scope: string, cache: WorkerConfigCache, adType: AdTypes): FetchWorker {
    return new FetchWorker({url, scope, cache, adType});
  }
}
