import type {ReactNode} from 'react';

import {v4 as uuid} from 'uuid';

import {getAdPodStartPayload} from 'components/Ads/TypeVideo/helpers/utils';
import {AbstractStrategy} from 'entities/strategies/api/abstract';
import generateAdJSX from 'entities/strategies/ui/generateAdJSX';
import {AdTypes} from 'features/adoppler/enums';
import fetchAd from 'features/adoppler/service/fetch';
import {setPreviousEpisode, type ChannelData, type AdBlock, type BarkerBlock} from 'shared/api/bootstrap-service';
import {ADOPPLER_RENDER_EVENT, VIDEO_AD_FINISHED_EVENT, WORKER_REGISTER_CUSTOM_AD_POD} from 'shared/constants';
import {
  logger as baseLogger,
  retryPromise,
  subscribe,
  triggerCustomEvent,
  unsubscribe,
} from 'shared/utils';
import {AndroidSDKEvent} from 'shared/utils/eventsSdk';

import type {AdConfig, AdStrategyName, UseAdReturnType} from 'types';

import type {AdStrategy} from 'entities/strategies/model';

const logger = baseLogger.child({tag: '[AdopplerBarkerStrategy]'});
const sdkEvent = new AndroidSDKEvent();

/**
 * Represents Adoppler MultiBid strategy
 * @implements {AdStrategy}
 */
class AdopplerBarkerStrategy extends AbstractStrategy implements AdStrategy {
  videoFinishHandler: (() => void) | undefined;
  timeoutId: NodeJS.Timeout | undefined;
  name: AdStrategyName;
  awaitPromise: ((result: boolean) => void) | undefined;
  loopStart: boolean = false;
  channelData: ChannelData | undefined;
  adUnitConfig: AdConfig;

  index: number;
  showId: string | undefined;
  currentQuartile: number;

  googleCanBePlayed: boolean = false;

  /**
   * Creates an instance of the AdopplerRandomWeightedStrategy.
   *
   * @constructor
   * @param {ChannelData | undefined} channelData - DeviceProps we need to pass to handle validation
   * @param {AdConfig} adUnitConfig - DeviceProps we need to pass to handle validation
   */
  constructor(channelData: ChannelData | undefined, adUnitConfig: AdConfig) {
    super();
    this.index = 0;
    this.name = 'adoppler-barker-strategy';
    this.channelData = channelData;
    this.adUnitConfig = adUnitConfig;
    this.showId = adUnitConfig.providers.gabriel.adoppler.config.showId;
    this.currentQuartile = 1;
  }

  /**
   * Run and managing fetching loop
   * @param {number} fetchInterval
   */
  public startLoop(fetchInterval: number): void {
    logger.info('Started strategy cycle', this.channelData, this.adUnitConfig);
    this.loopStart = true;

    if (this.channelData?.episode) {
      setPreviousEpisode(this.channelData?.episode);
      sdkEvent.adPodStart(getAdPodStartPayload('', []));
    }

    if (this.channelData && this.adUnitConfig) {
      this.registerCustomAdPodWorkers(this.channelData.blocks);

      (async () => {
        this.videoFinishHandler = () => {
        // we have active request, we should stop it before send new one on demand
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
          }

          if (this.awaitPromise) {
            this.awaitPromise(true);
          }
        };

        subscribe(VIDEO_AD_FINISHED_EVENT, this.videoFinishHandler);

        while (this.loopStart) {
          await this.run(fetchInterval);
        }
      })();
    }
  }

  /**
   * Fetch response from the cache
   * @param {number} fetchInterval
   * @param {number} adPodDuration
   * @private
   * @return {Promise<UseAdReturnType>}
   */
  private async getAdPodResponse(fetchInterval: number, adPodDuration: number): Promise<UseAdReturnType> {
    return await retryPromise({
      promiseFn: () => fetchAd(AdTypes.AdPod, fetchInterval, true, adPodDuration),
      isNeedRetry: (result) => {
        logger.info('is need retry cache request', result);
        return result?.adType === AdTypes.DefaultTelly;
      },
      timeout: 1000,
      maxRetries: 5,
    });
  }

  /**
   * Unsubscribe from active videoHandler
   */
  private unsubscribeVideoHandler() {
    if (this.videoFinishHandler) {
      unsubscribe(VIDEO_AD_FINISHED_EVENT, this.videoFinishHandler);
      this.videoFinishHandler = undefined;
    }
  }

  /**
   * Method to play video
   * @param {UseAdReturnType} response
   * @private
   * @return {Promise<boolean>}
   */
  private async playVideo(
    response: UseAdReturnType,
  ): Promise<boolean> {
    return new Promise((r) => {
      this.awaitPromise = r;
      triggerCustomEvent(ADOPPLER_RENDER_EVENT, {...response, disableAdEvents: true});
    });
  }

  /**
   * Method to play video
   * @param {string} barkerUrl
   * @private
   * @return {Promise<boolean>}
   */
  private async playBarker(
    barkerUrl: string,
  ): Promise<boolean> {
    return new Promise((r) => {
      this.awaitPromise = r;

      triggerCustomEvent(ADOPPLER_RENDER_EVENT, {
        adType: AdTypes.Barker,
        adSettings: [{adUrls: [barkerUrl],
          barkerData: {
            channelId: String(this.showId),
            episodeId: String(this.channelData?.episode),
            blockId: this.index.toString(),
          },
        }],
        adResponseId: '',
      });
    });
  }

  /**
   * Register new worker
   * @param {Array<BarkerBlock | AdBlock>} blocks
   * @protected
   */
  protected registerCustomAdPodWorkers(blocks: Array<BarkerBlock | AdBlock>) {
    const customDurations = blocks.filter((block) => block.type === 'ad')
      .map((block) => ((block as AdBlock).duration)/1000);
    (new Set(customDurations)).forEach((duration) => {
      triggerCustomEvent(WORKER_REGISTER_CUSTOM_AD_POD, {duration});
    });
  }

  /**
   * Run loop
   * @param {number} fetchInterval
   * @private
   */
  private async run(fetchInterval: number) {
    if (!this.channelData) {
      return;
    }
    const blocks = this.channelData.blocks;
    const block = blocks[this.index];
    logger.info('loop run', {block, index: this.index});

    if (block?.type === 'ad' || blocks.length === this.index) {
      // find last ad block
      const lastAdBlock = blocks.reduceRight<AdBlock | undefined>((acc, b) => {
        if (acc === undefined && b.type === 'ad') {
          return b;
        }
        return acc;
      }, undefined) as AdBlock;

      const response = await this.getAdPodResponse(
        fetchInterval,
        (block as AdBlock)?.duration ?? lastAdBlock?.duration,
      );

      logger.info('get response from cache', response);
      if (response.adType !== AdTypes.DefaultTelly) {
        await this.playVideo(response);
      } else if (blocks.length === this.index) {
        // to prevent switching to screensaver unit
        sdkEvent.sendHeartbeat();
        // show telly logo and wait when there are no ads and barkers
        await new Promise((resolve) => {
          this.awaitPromise = resolve;

          triggerCustomEvent(ADOPPLER_RENDER_EVENT, {
            adType: AdTypes.DefaultTelly,
            adSettings: [],
            adResponseId: uuid(),
          });

          this.timeoutId = setTimeout(() => {
            resolve(true);
          }, fetchInterval);
        });
      }
    } else if (block?.type === 'barker') {
      await this.playBarker(block.url);
    }

    if (this.index < blocks.length) {
      this.index++;
    }
  }

  /**
   * Stop running fetching loop
   */
  public stopLoop(): void {
    this.loopStart = false;
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
    }

    this.unsubscribeVideoHandler();

    // show default logo
    triggerCustomEvent(ADOPPLER_RENDER_EVENT, {adType: AdTypes.DefaultTelly, adSettings: [], adResponseId: uuid()});
  }

  /**
   * Retrieves an ad using the strategy.
   *
   * @return {ReactNode | null} The JSX element representing the selected ad.
   */
  public getAd(): ReactNode | null {
    return generateAdJSX(this.name, this.startLoop.bind(this), this.stopLoop.bind(this), this.googleCanBePlayed);
  }
}

export {AdopplerBarkerStrategy};
