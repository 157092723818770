import {MetricsSingleton, type MetricsService, gabrielLifecycleMetrics} from 'shared/api/metrics-service';
import {FREESTAR_NOT_LOADED_EVENT, GOOGLE_AD_EMPTY_EVENT} from 'shared/constants';
import {analytics, triggerCustomEvent} from 'shared/utils';
import {amazonAdsManager} from 'shared/utils/amazon-ads';
import {AndroidSDKEvent} from 'shared/utils/eventsSdk';
import {logger as baseLogger} from 'shared/utils/logger';

import type {DeviceInfo} from 'types';

import {notifyOnNullishSlot, retrieveIDFromURL, sizeChangeEvent} from './helpers';

import type {FreestarSlot} from './types';

const logger = baseLogger.child({tag: '[Freestar Ads]'});

/**
 * Class representing Freestar functionalities.
 */
export class Freestar {
  private refreshIntervalId: NodeJS.Timeout | undefined;
  public googleSlotId: string | undefined;
  public creativeId: string | undefined;
  public advertiser_name: string | undefined;
  public wasRendered: boolean = false;
  public isBackfill: boolean = false;

  /** Uses for preventing refreshing the AD if at present time
   * displays the Adoppler ad. This fieled gives an opportunity
   * to refresh the AD only if Freestar playing.
   */
  public adsenseWasPlayed: boolean = true;
  private metricsGabrielGoogle: MetricsService;
  private metricsGabrielAdAnalytics: MetricsService;

  /**
   * @constructor
   */
  constructor() {
    this.metricsGabrielGoogle = MetricsSingleton.getService('Gabriel/Google');
    this.metricsGabrielAdAnalytics = MetricsSingleton.getService('Gabriel/AdAnalytics');
    this.loadScriptAsync().catch((e) => {
      logger.warn('Freestar script not loaded', e);
    });
  }

  /**
   * Removes all initialized scripts and links from the document head.
   */
  public destroy() {
    const freestar = window.freestar || {};
    freestar.queue = window.freestar?.queue || [];

    freestar.queue.push(() => {
      window.googletag.pubads().removeEventListener('slotRenderEnded', this.slotRenderEnded);
      window.googletag.pubads().removeEventListener('slotRequested', this.slotRequested);
      window.googletag.destroySlots();
      freestar.deleteAdSlots();
      amazonAdsManager.destroy();
    });
    clearTimeout(this.refreshIntervalId);
    logger.info('Slots destroyed');
  }

  /**
   * Adds a slot to the Freestar configuration.
   * @param {FreestarSlot[]} fsSlots The slot to add.
   * @param {string} hashedEmail the hashed email from device properties.
   * @param {DeviceInfo} deviceProperties
   * @param {boolean} coppa child directed treatment.
   */
  public addHashedEmailAndSlots(
    fsSlots: FreestarSlot[],
    hashedEmail: string | undefined,
    deviceProperties: DeviceInfo,
    coppa?: boolean,
  ) {
    logger.info('add hashed email and slots');
    window.googletag = window.googletag || {cmd: []};

    const freestar = window.freestar || {};
    freestar.queue = window.freestar?.queue || [];
    freestar.config = window.freestar?.config || {};
    freestar.config.targeting = freestar.config.targeting || [];
    freestar.debug = __DEV__;
    freestar.config.enabled_slots = fsSlots;

    this.pushHashedEmail(hashedEmail);

    freestar.queue.push(() => {
      logger.info('Init googletag');
      window.googletag.pubads().addEventListener('slotRenderEnded', this.slotRenderEnded);
      window.googletag.pubads().addEventListener('slotRequested', this.slotRequested);

      const placementTargeting: {[key: string]: string | string[]} = {};

      const isLat = Boolean(deviceProperties?.ad_info?.lmt);
      const rdid = deviceProperties?.ad_info?.ifa;
      const idtype = deviceProperties?.ad_info.ifa_type || 'adid';

      fsSlots.forEach((fsSlot) => {
        amazonAdsManager.fetchBids(
          {
            slots: [
              {
                slotID: fsSlot.slotId,
                slotName: fsSlot.placementName,
                sizes: [[300, 250], [514, 290]],
              },
            ],
            timeout: 2e3,
          },
          function(bids) {
            freestar.queue.push(function() {
              amazonAdsManager.setDisplayBids();
              logger.info('APS Bids:', bids);
            });
          },
        );
        fsSlot.targeting.forEach(({key, value})=> {
          window.googletag.pubads().setTargeting(key, value);
          placementTargeting[key] = value;
        });

        // Set privacy settings and additional targeting parameters
        window.googletag.pubads().setPrivacySettings({
          childDirectedTreatment: coppa,
          limitedAds: isLat,
        });

        window.googletag.pubads().setTargeting('is_lat', isLat.toString());
        window.googletag.pubads().setTargeting('rdid', rdid);
        window.googletag.pubads().setTargeting('idtype', idtype);

        freestar.config.targeting.push({
          [fsSlot.placementName]: placementTargeting,
        });
      });
    });

    let timeout: NodeJS.Timeout;
    if (freestar.initCallbackCalled) {
      freestar.queue.push(function() {
        freestar.newAdSlots(freestar.config.enabled_slots);
        logger.info('Added slots:', freestar.config.enabled_slots);
      });
    } else {
      const SKIP_FREESTAR_TIMEOUT = 5 * 1000;
      timeout = setTimeout(() => {
        logger.info('skip freestar');
        triggerCustomEvent(FREESTAR_NOT_LOADED_EVENT, {timestamp: Date.now()});
      }, SKIP_FREESTAR_TIMEOUT);
    }

    freestar.initCallback = function() {
      clearTimeout(timeout);
      logger.info('freestar init callback', freestar.initCallbackCalled, freestar.config.enabled_slots.length);
      freestar.queue.push(function() {
        if (__DEV__) {
          window.googletag.pubads().set('page_url', 'freetelly.com');
        }
        freestar.newAdSlots(freestar.config.enabled_slots);
        logger.info('Added slots initial:', freestar.config.enabled_slots);
      });
    };
  }

  /**
   * Refresh Ad
   */
  public refreshAd() {
    const freestar = window.freestar || undefined;

    if (freestar) {
      logger.info('Refresh FreeStar Ad');
      freestar.queue.push(function() {
        freestar.refresh();
      });
    }
  }

  /**
   * Adds an event listener for the 'sizeChanged' event.
   * @param {CallableFunction} handler The event handler function to be called when the size changes.
   */
  public addSizeChangedEventListener(handler: (event: CustomEvent<{size: string | number[] | null;}>) => void) {
    window.addEventListener('sizeChanged', handler as EventListener);
  }

  /**
   * Removes an event listener for the 'sizeChanged' event.
   * @param {CallableFunction} handler The event handler function to be called when the size changes.
   */
  public removeSizeChangedEventListener(handler: (event: CustomEvent<{size: string | number[] | null;}>) => void) {
    window.removeEventListener('sizeChanged', handler as EventListener);
  }

  /**
 * Retrieves the creative ID of the current ad slot.
 * @return {string | undefined} The creative ID of the current ad slot if available, otherwise undefined.
 */
  public getCreativeId(): string | undefined {
    if (window.freestar?.initCallbackCalled) {
      const adSlotName = window.freestar.newAdSlotIds[0];

      return window.freestar.dfpSlotInfo[adSlotName]?.creativeId?.toString();
    }
  }

  /**
 * Detects the version of Chrome browser from the User-Agent string.
 * @private
 * @return {?number} The version of Chrome browser if detected, otherwise null.
 */
  private detectChromeVersion(): number | null {
    const ua = navigator.userAgent;
    const chromeMatch = ua.match(/Chrome\/(\d+)/);
    return chromeMatch ? parseInt(chromeMatch[1]) : null;
  }

  /**
 * Gets the URL of the script based on the detected Chrome version.
 * Note: This is a temporary solution.
 * @private
 * @return {string} The URL of the script.
 */
  private getScriptUrl(): string {
    const chromeVersion = this.detectChromeVersion();
    const url120 = 'https://a.pub.network/freetelly-com/test/46/pubfig.min.js?bypass=true';
    const initialUrl = 'https://a.pub.network/freetelly-com/pubfig.min.js';

    if (chromeVersion === 120) {
      return url120;
    } else if (chromeVersion === 83) {
      return initialUrl;
    } else {
      return initialUrl;
    }
  }

  /**
 * Loads the script asynchronously and returns a Promise.
 * @private
 * @return {Promise<HTMLScriptElement>} A Promise that resolves with the loaded script element if successful, otherwise rejects with an error.
 */
  private loadScriptAsync(): Promise<HTMLScriptElement> {
    return new Promise((resolve, reject) => {
      const scriptUrl = this.getScriptUrl();
      const script = document.createElement('script');
      script.id = 'pubfig-script';
      script.src = scriptUrl;
      script.async = true;

      script.onload = () => {
        resolve(script);
      };

      script.onerror = () => {
        reject(new Error('Failed to load script'));
        // Trigger the FREESTAR_NOT_LOADED_EVENT custom event if script loading failed.
        triggerCustomEvent(FREESTAR_NOT_LOADED_EVENT, {});
      };

      document.head.appendChild(script);
    });
  }

  /**
 * Handles the slot requested event from GPT.
 * This function is triggered when a slot request is made.
 *
 * @param {googletag.events.SlotRequestedEvent} event - The event object representing the slot requested.
 * @return {void}
 */
  private slotRequested(): void {
    gabrielLifecycleMetrics.emitEvent('AdSlotRefreshStart');
  }

  /**
 * Handles the event when a GPT slot render ends.
 * @param {googletag.events.SlotRenderEndedEvent} event The event object representing the slot render.
 * @return {void}
 */
  private slotRenderEnded = (event: googletag.events.SlotRenderEndedEvent): void => {
    logger.info('slot render ended', this);
    const targetSlot = 'native-ad-fs';
    const slot = event.slot.getSlotElementId();
    const adUnitPath = event.slot.getAdUnitPath();
    const googleSlotId = retrieveIDFromURL(adUnitPath);

    // @see https://teevee.atlassian.net/browse/TV1-11596
    if (slot === targetSlot) {
      if (event.isEmpty) {
        if (__DEV__) {
          window.googletag.pubads().set('page_url', 'https://www.telly.com/');
        }
        this.wasRendered = false;
        logger.info('Get no ads in the Google response', event);
        triggerCustomEvent(GOOGLE_AD_EMPTY_EVENT, {timestamp: Date.now()});
      } else {
        const sdkEvent = new AndroidSDKEvent();
        sdkEvent.adStarted('gpt', undefined);
        sizeChangeEvent(event.size);
        this.wasRendered = true;
      }
    } else if (!event.slot) {
      logger.info(`Can't create a slot`);
      notifyOnNullishSlot(googleSlotId);
      this.wasRendered = false;
    }

    this.googleSlotId = googleSlotId;
    this.creativeId = event.creativeId?.toString();
    this.advertiser_name = event.advertiserId?.toString();
    this.adsenseWasPlayed = false;
    this.isBackfill = event.isBackfill;
  };

  /**
 * Pushes a hashed email to the Freestar identity queue.
 * The hashed email is set as the identity using SHA-256 hashing algorithm.
 * @param {string} hashedEmail The hashed email to be pushed.
 * It should be a SHA-256 hashed string representing the email.
 */
  private pushHashedEmail(hashedEmail: string | undefined) {
    const freestar = window.freestar || {};
    freestar.queue = window.freestar?.queue || [];

    if (hashedEmail) {
      // Push a function to the queue that sets the hashed email as identity using SHA-256 hashing
      freestar.queue.push(function() {
        freestar.identity.setIdentity({
          hashes: {
            sha256: hashedEmail,
          },
        });
      });
    } else {
      logger.warn('No "user_identifiers.uid2" provided.');
    }
  }

  /**
   * Trigger analytic module
   */
  public triggerAnalytic() {
    if (this.googleSlotId) {
      analytics
        .emitAdEvent('Request', {
          ad_server: 'gam',
          ad_unit_id: this.googleSlotId,
        });

      if (this.wasRendered) {
        analytics.emitAdEvent('Impression', {
          ad_server: 'gam',
          creative_id: this.creativeId,
          advertiser_name: this.advertiser_name,
          ad_unit_id: this.googleSlotId,
          creative_permutation_type: 'eve_house_ad_emptyadpanel',
        });
      }
    }
  }

  /**
   * Trigger metrics module
   */
  public triggerMetric(): void {
    if (this.googleSlotId) {
      this.metricsGabrielGoogle.emitEvent('Request');
      this.metricsGabrielAdAnalytics.emitEvent('AdRequest', [{Name: 'type', Value: 'Freestar'}]);
    }

    let responseType = 'empty';
    if (this.wasRendered) {
      responseType = this.isBackfill? 'house' : 'paid';
    }
    this.metricsGabrielGoogle.emitEvent('Response', [{Name: 'status', Value: responseType}]);
    this.metricsGabrielAdAnalytics.emitEvent('AdImpression', [{Name: 'type', Value: 'Freestar'}]);
  }
}

const freestarManager = new Freestar();

export default freestarManager;
