import {useRef} from 'react';

import {useRemoteControllerEvent, useSoundMute} from 'components/Ads/TypeVideo/helpers';
import {useAdUnitConfigContext, useAppContext} from 'context';

/**
 * Hook to manage mute state from various sources.
 *
 * @param {boolean} [defaultMute=false] - Default muted state
 * @return {React.MutableRefObject<boolean>} mutedStateRef - Muted state reference
*/
export const useMute = (defaultMute?: number): React.MutableRefObject<boolean> => {
  const {adUnitConfig} = useAdUnitConfigContext();
  const {deviceProperties} = useAppContext();

  const isVideoMutedByDefault = deviceProperties?.is_video_muted ?? Boolean(defaultMute == 1);
  const staticMutedRef = useRef(isVideoMutedByDefault);
  const lastEventTimestampRef = useRef(Date.now());
  const prevMutedStateRef = useRef(isVideoMutedByDefault);

  const isEveFullscreenTemplate = adUnitConfig?.providers.gabriel?.adoppler.template === 'eve-screen-fullscreen';

  const {muted} = useSoundMute(isVideoMutedByDefault);
  const {eventTimestamp} = useRemoteControllerEvent(isEveFullscreenTemplate, lastEventTimestampRef.current);

  // in case event happens we should revert muted flag
  if (eventTimestamp != lastEventTimestampRef.current) {
    staticMutedRef.current = !staticMutedRef.current;
    lastEventTimestampRef.current = eventTimestamp;
  }

  // in case we received muted event
  if (prevMutedStateRef.current != muted) {
    staticMutedRef.current = muted;
    prevMutedStateRef.current = muted;
  }

  return staticMutedRef;
};
