import {memo, useEffect} from 'react';

import {meteEnvConfig} from 'config';
import {gabrielLifecycleMetrics, MetricsSingleton} from 'shared/api/metrics-service';
import {TellyLogoSvg} from 'shared/components';
import {analytics} from 'shared/utils';

import './index.css';

interface TypeTellyAdProps {
  loaded?: boolean
}
const metricService = MetricsSingleton.getService('Gabriel/AdAnalytics');
const TellyAd = memo(({loaded = true}: TypeTellyAdProps) => {
  const adUnit = meteEnvConfig.ads.adUnit;
  const isEveScreen = adUnit == 'EVE_FULLSCREEN_UNIT';

  useEffect(() => {
    if (loaded) {
      analytics.emitAdEvent('Request', {
        ad_server: 'elemental',
      });

      metricService.emitEvent('AdError', [{Name: 'type', Value: 'TellyLogo'}]);
      analytics.emitAdEvent('Error', {
        ad_error_code: `error`,
        ad_error_log_response: 'error while loading ads',
        ad_error_source: 'elemental',
      });

      gabrielLifecycleMetrics.emitEvent('TellyLogoDisplay');
    }
  }, [loaded]);

  return (
    <div
      data-testid="StrategyManager"
      className={`telly-container ${isEveScreen ? 'eve-logo-container' : null}`}
    >
      <div className={`telly-wrapper ${isEveScreen ? 'eve-logo-wrapper' : null}`}>
        <TellyLogoSvg className='logo' />
      </div>
    </div>
  );
});

export default TellyAd;
