import Joi from 'joi';

import {logger as baseLogger} from 'shared/utils/logger';

import type {ACRResponse, ACRPayload} from './types';

const logger = baseLogger.child({tag: '[Acr Event Validator]'});

type ValidateReturnType = {
  validAcrResponse: Array<ACRPayload>
}

/**
 * Validates acr events
 *
 * @param {ACRResponse} acrResponse - The acrResponse to validate.
 * @return {ValidateReturnType}.
 */
export function validateAcrResponse(acrResponse: ACRResponse): ValidateReturnType {
  const acrPayloadSchema = Joi.object({
    id: Joi.string().required(),
    type: Joi.string().optional(),
    typeMetadata: Joi.alternatives().try(
      Joi.object({
        type: Joi.string().optional(),
        brandId: Joi.string().optional(),
        categoryId: Joi.string().optional(),
        iabCategoryId: Joi.string().optional(),
      }),
      Joi.any().allow(null),
    ).optional(),
    blacklist: Joi.boolean().optional(),
  });

  if (acrResponse.type === 'Match') {
    const {error} = acrPayloadSchema.validate(acrResponse.payload, {abortEarly: false, convert: false});
    logger.info('validation', error, acrResponse.payload);

    return {validAcrResponse: !error ? [acrResponse.payload] : []};
  } else if (acrResponse.type === 'MultiMatch') {
    // find any valid metadata
    const validAcrResponse = acrResponse.payload.filter(
      (payload) => {
        const {error} = acrPayloadSchema.validate(payload, {abortEarly: false, convert: false});
        return !error;
      },
    );

    return {validAcrResponse};
  }

  return {validAcrResponse: []};
}
