import {useEffect, useState} from 'react';

import {globalShared} from 'shared';
import {RC_BUTTON_PRESS_EVENT} from 'shared/constants';

import type {CustomEventPayload} from 'types';

interface Payload {
  button: 'A' | 'B'
}

interface ControlEvent extends CustomEventPayload {
  payload: Payload
}

/**
 * Custom hook to control audio based on custom events with a specific payload structure.
 *
 * @param {boolean} isEveFullscreenTemplate
 * @param {number} initialTime
 * @return {{ eventTimestamp: number }} Last time event happens
*/
function useRemoteControllerEvent(isEveFullscreenTemplate: boolean, initialTime: number): { eventTimestamp: number } {
  const {eventDetail} = globalShared.useCustomEventHandler<ControlEvent>(RC_BUTTON_PRESS_EVENT);

  const [eventTimestamp, setEventTimeStamp] = useState(initialTime);

  useEffect(() => {
    if (eventDetail) {
      if (!isEveFullscreenTemplate && eventDetail.payload.button === 'A') {
        setEventTimeStamp(Date.now());
      } else if (isEveFullscreenTemplate && eventDetail.payload.button === 'B') {
        setEventTimeStamp(Date.now());
      }
    }
  }, [eventDetail, isEveFullscreenTemplate]);

  return {eventTimestamp};
}

export default useRemoteControllerEvent;
