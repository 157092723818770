import {sendActionTrackerEventEmitter} from './index';

const messages: {data: Record<string, unknown>}[] = [];

/**
 * Handles Telly SDK messages by adding the provided data to the messages array
 * and emitting a 'debug-telly-sdk' event using the sendActionTrackerEvetEmitter.
 *
 * @param {Record<string, unknown>} data - The Telly SDK message data to be handled.
 * @return {void}
 */
export const handleTellySdkMessages = (data: {data: Record<string, unknown>}): void => {
  messages.push(data);
  const message = messages.at(-1);
  if (__DEV__ && message) {
    console.debug(`%cTelly Analytics: `,
      'background: #EE5F3F; color: white; padding: 2px 8px; border-radius: 4px',
      {...message, ...{...message.data?.eventType ? {eventType: message.data.eventType} : undefined} },
    );
  }
  sendActionTrackerEventEmitter.emit('debug-telly-sdk', {messages});
};
