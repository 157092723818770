import pino, {levels} from 'pino';

import {meteEnvConfig} from 'config';

import {sendEventToParent} from './event';

/**
 * Configuration type for logger settings.
 * @property {pino.Level} level - The log level.
 * @property {(message: string) => void} send - Function to send log messages.
 */
type LoggerConfig = {
  name: string,
  level: pino.Level,
  send: (logEvent: pino.LogEvent) => void
}

export const getMessagesFromLogEvent = (logEvent: pino.LogEvent): unknown[] => {
  const messages = [...logEvent.messages];
  const [binding] = logEvent.bindings;
  if (binding?.tag) {
    messages.unshift(binding?.tag);
  }
  const adUnit = meteEnvConfig.ads.adUnit;
  messages.unshift(adUnit);
  return messages;
};

export const stringifyLogMessage = (messages: unknown[]) => {
  return messages.map((m) => {
    try {
      let cache: unknown[] = [];
      const stringified = JSON.stringify(m, (_, value) => {
        if (typeof value === 'object' && value !== null) {
          // Duplicate reference found, discard key
          if (cache.includes(value)) return;

          // Store value in our collection
          cache.push(value);
        }
        return value;
      });
      cache = [];
      return stringified;
    } catch (error) {
      console.warn('Error stringify log message', error);
      return '';
    }
  }).join(' ');
};

/**
 * Array of logger configurations.
 */
export const loggerConfig: Array<LoggerConfig> = [
  {
    name: 'web-view-logger',
    level: 'debug',
    send: (logEvent: pino.LogEvent) => {
      const messages = getMessagesFromLogEvent(logEvent);
      const stringified = stringifyLogMessage(messages);
      sendEventToParent(stringified);
    },
  }, {
    name: 'browser-logger',
    level: 'trace',
    send: (logEvent: pino.LogEvent) => {
      const browserLoggerBindings: {[key in pino.Level]: (message?: unknown, ...optionalParams: unknown[]) => void} = {
        trace: console.trace,
        debug: __DEV__ ? console.debug : console.info,
        info: console.info,
        warn: console.warn,
        error: console.error,
        fatal: console.error,
      };

      const log = browserLoggerBindings[logEvent.level.label as pino.Level];
      const messages = getMessagesFromLogEvent(logEvent);
      const stringified = stringifyLogMessage(messages);
      if (__DEV__) {
        log(...messages);
      } else {
        log(stringified);
      }
    },
  },
];

/**
 * Logger instance configured with custom log transmission.
 * @type {pino}
 */
export const logger = pino({
  serializers: {
    error: pino.stdSerializers.err,
  },
  browser: {
    // block default broswer output because of 'transmit' gives more detailed logEvent
    write() {},
    serialize: true,
    transmit: {
      level: 'trace',
      /**
       * Custom log transmission function.
       * @param {pino.Level} _level - The log level of the current log event.
       * @param {pino.LogEvent} logEvent - The log event being transmitted.
       */
      send: (_level: pino.Level, logEvent: pino.LogEvent) => {
        // send the logEvent somewhere
        for (const service of loggerConfig) {
          if (levels.values[service.level] < logEvent.level.value) {
            service.send(logEvent);
          }
        }
      },
    },
  },
});
