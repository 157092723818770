import {meteEnvConfig} from 'config';
import {useAdUnitConfigContext} from 'context';
import {type AdStrategy} from 'entities/strategies';
import {logger as baseLogger} from 'shared/utils/logger';

import type {ChannelData} from 'shared/api/bootstrap-service';

const logger = baseLogger.child({tag: '[AdStrategyManager Hook]'});

/**
 * Hook for managing advertising strategies.
 *
 * Accepts an array of strategy instances and is responsible for
 * selecting one of them to use for ad rendering.
 *
 * @param {Array<AdStrategy>} strategies - Array of AdStrategy instances to choose from
 * @param {ChannelData | undefined} channelData - Array of AdStrategy instances to choose from
 * @return {AdStrategy} The chosen AdStrategy instance
 */
export const useAdStrategyManager = (
  strategies: AdStrategy[],
  channelData: ChannelData | undefined = undefined,
): AdStrategy | null => {
  const {adUnitConfig} = useAdUnitConfigContext();

  if (!adUnitConfig) {
    return null;
  }

  const featureFlags = adUnitConfig.features;
  const isAdamVideoUnitScreen = meteEnvConfig.ads.adUnit === 'ADAM_SCREENSAVER_VIDEO_UNIT';

  logger.info('Initialized AdUnitConfig in useAdStrategyManager hook', adUnitConfig);

  /**
   * Chooses which strategy to use for ad selection.
   *
   * Checks the configured 'strategy' name against the provided
   * strategies and returns the matching one.
   *
   * @return {AdStrategy} The chosen AdStrategy instance
   */
  const chooseStrategy = (): AdStrategy | null => {
    if (!adUnitConfig || (isAdamVideoUnitScreen && !channelData)) {
      return null;
    }

    const {providers} = adUnitConfig;

    const configuredStrategyName = meteEnvConfig.ads.strategy ?? providers.gabriel?.adoppler.strategy;
    let strategyName = configuredStrategyName;

    if (isAdamVideoUnitScreen && !featureFlags?.enableBarkerChannel?.enable) {
      strategyName = 'adoppler-strategy';
    }

    const strategy = strategies.find((s) => s.name === strategyName);
    if (configuredStrategyName && !strategy) {
      logger.warn('Unknown strategy', configuredStrategyName);
    } else {
      logger.info('Detected strategy name', `"${strategy?.name}"`);
    }

    return strategy || strategies[0] || null;
  };

  return chooseStrategy();
};
