import Joi from 'joi';

import {logger as baseLogger} from 'shared/utils/logger';

import type {NativeAd} from 'features/adoppler/types/native';

const logger = baseLogger.child({tag: '[Ad Parsing Validator]'});

const eventTrackerSchema = Joi.object({
  url: Joi.string().required(),
  event: Joi.number().required(),
  method: Joi.number().required(),
});

const impressionSchema = Joi.object<NativeAd.Impression>({
  ver: Joi.string().required(),
  assets: Joi.array().items(
    Joi.object({
      id: Joi.number().required(),
      img: Joi.object({
        type: Joi.number().required(),
        url: Joi.string().required(),
        w: Joi.number().required(),
        h: Joi.number().required(),
      }),
      data: Joi.object({
        type: Joi.number().required(),
        value: Joi.string().required(),
      }),
    }),
  ).required(),
  link: Joi.object({
    url: Joi.string().required(),
    clicktrackers: Joi.array().items(Joi.string()).required(),
  }).required(),
  eventtrackers: Joi.array().items(eventTrackerSchema).required(),
});

/**
 * Validates the structure of an Ad Click Tracker impression using Joi.
 *
 * @param {NativeAd.Impression} impression - The Ad Click Tracker impression to validate.
 * @return {Joi.ValidationResult<NativeAd.Impression>} - The result of the validation.
 */
function validateParseAdClickTracker(
  impression: NativeAd.Impression,
): Joi.ValidationResult<NativeAd.Impression> {
  const validationResult = impressionSchema.validate(impression, {abortEarly: false});

  const {error, warning} = validationResult;

  if (error || warning) {
    logger.warn('Ad Parsing Ad Click Tracker validation warning', {error, warning});
  }

  return validationResult;
}

export default validateParseAdClickTracker;
