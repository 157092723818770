import {logger as baseLogger} from 'shared/utils/logger';

const logger = baseLogger.child({tag: '[Internet speed]'});

/**
 * Monitors the internet connection speed and logs it to the console.
 *
 * This function checks for the availability of the `navigator.connection` property
 * and its alternatives (`mozConnection` and `webkitConnection`) in different browsers.
 * If available, it retrieves the downlink speed and effective connection type.
 * The downlink speed is then converted to Kilobits per second (Kb/s) and logged to the console.
 *
 * If the connection information is not available, a message is logged to the console.
 */
export default function monitorInternetSpeed() {
  const connection: NavigatorConnection | undefined =
    navigator?.connection || navigator?.mozConnection || navigator?.webkitConnection;

  if (connection) {
    const {downlink = 1, effectiveType} = connection;

    // Convert downlink speed to Kilobits per second (Kb/s)
    const speedKbps = downlink * 1024;

    logger.info(speedKbps.toFixed(2), `Kb/s (Connection type: ${effectiveType})`);
  } else {
    logger.warn('Internet speed information not available.');
  }
}
