
import {APP_LIST_UPDATE_EVENT} from 'shared/constants';
import {logger as baseLogger} from 'shared/utils/logger';

import {processedAppAlternate} from './helpers';
import {appListStub} from './stub/appListStub';

import type {DeeplinkAction} from 'features/adoppler';

const logger = baseLogger.child({tag: '[Permutation Service]'});

/**
 * Class representing a Telly application.
 */
export class PermutationService {
  /**
   *
   */
  constructor() {
    this.subscribeToAppListUpdate();
  }

  /**
   * Sets the list of applications in local storage.
   * If running in a development environment, it uses a stubbed list. Otherwise, it retrieves the list from the TellySDK.
   */
  public setList() {
    if (typeof window !== 'undefined' && window.TellySDK?.getAppList) {
      const appList = window.TellySDK.getAppList();
      localStorage.setItem('app-list', appList);
    } else if (__DEV__) {
      localStorage.setItem('app-list', JSON.stringify(appListStub));
    } else {
      localStorage.setItem('app-list', JSON.stringify(appListStub));
      logger.warn('TellySDK method "getAppList()" is not defined!');
    }
  }

  /**
 * Checks if the dongle is connected using the TellySDK method.
 * @return {boolean} Returns true if the dongle is connected, false otherwise.
 */
  public isDongleAvailable(): boolean {
    if (typeof window !== 'undefined' && window.TellySDK?.isDongleConnected) {
      return window.TellySDK?.isDongleConnected();
    } else {
      logger.warn('TellySDK method "isDongleConnected" is not defined!');
      return false;
    }
  }

  /**
   * Cleanup resources when the instance is destroyed.
   */
  public destroy() {
    this.unsubscribeFromAppListUpdate();
  }

  /**
 * Updates the launchers of an app in the app list with new `data_uri` and `market_uri` values from the provided DeeplinkAction.
 *
 * @param {DeeplinkAction} action - The action containing the parameters for the deeplink, including the package to filter by, the new data URI, and the new market URI.
 * @return {TellyAppObject.Root | undefined} - The updated app object with the modified launchers, or undefined if no matching app is found.
 */
  public combineTTMDeeplink(action: DeeplinkAction): TellyAppObject.Root | undefined {
    const appList = this.getList();
    if (!appList) {
      logger.warn('Telly App List is not in localStorage or localStorage is unavailable.');
      return;
    }
    const {package: packageToFilter, url, market} = action.params;

    const selectedApp = appList.find((app) =>
      app.launchers?.some((launcher) => launcher.type === 'ecp' && launcher.metadata.package === packageToFilter),
    );

    if (!selectedApp) {
      const appAlternate = processedAppAlternate({package: packageToFilter, data_uri: url, market_uri: market});
      logger.warn('The app not found in list. Package:', packageToFilter);
      logger.info('Sent alternate app:', appAlternate);
      return appAlternate;
    }

    const updatedLaunchers = selectedApp.launchers.map((launcher) => {
      if (launcher.type === 'ecp' && launcher.metadata.package === packageToFilter) {
        return {
          ...launcher,
          metadata: {
            ...launcher.metadata,
            data_uri: url,
            market_uri: market,
          },
        };
      }
      return launcher;
    });

    const updatedAppList = appList.map((app) =>
      app === selectedApp ? {...selectedApp, launchers: updatedLaunchers} : app,
    );

    localStorage.setItem('app-list', JSON.stringify(updatedAppList));
    return {...selectedApp, launchers: updatedLaunchers} as TellyAppObject.Root;
  }

  /**
   * Subscribes to the APP_LIST_UPDATE_EVENT.
   * @private
   */
  private subscribeToAppListUpdate() {
    window.addEventListener(APP_LIST_UPDATE_EVENT, () => {
      this.setList();
      logger.info(`Received 'APP_LIST_UPDATE_EVENT' event`);
    });
  }

  /**
   * Unsubscribes from the APP_LIST_UPDATE_EVENT.
   * @private
   */
  private unsubscribeFromAppListUpdate() {
    window.removeEventListener(APP_LIST_UPDATE_EVENT, this.setList.bind(this));
  }

  /**
   * Retrieves the list of applications from local storage.
   * @return {TellyAppObject.Root[] | undefined} The list of applications.
   */
  private getList(): TellyAppObject.Root[] | undefined {
    try {
      const appList = localStorage.getItem('app-list');
      if (appList) {
        return JSON.parse(appList) as TellyAppObject.Root[];
      } else if (__DEV__) {
        return appListStub;
      } else {
        logger.warn('The APP LIST did not recorded to the localStorage!');
        return undefined;
      }
    } catch (error) {
      logger.warn(error);
      return undefined;
    }
  }
}

const permutationService = new PermutationService();

export default permutationService;
