import {useEffect, useState} from 'react';

import {globalShared} from 'shared';

import type {CustomEventPayload} from 'types';

interface Payload {
  action: 'mute' | 'unmute'
}

interface VideoSoundEvent extends CustomEventPayload {
  payload: Payload
}

/**
 * Custom hook to control sound manipulation to mute/unmute video
 * @param {boolean} isMuted default muted flag
 * @return {{ muted: boolean }} An object representing the audio state with a 'muted' property.
*/
function useSoundMute(isMuted: boolean): { muted: boolean; } {
  const {eventDetail} = globalShared.useCustomEventHandler<VideoSoundEvent>('video');
  const [muted, setMuted] = useState(isMuted);

  useEffect(() => {
    if (eventDetail) {
      setMuted(eventDetail.payload.action == 'mute');
    }
  }, [eventDetail]);

  return {muted};
}

export default useSoundMute;
