
import {meteEnvConfig} from 'config';
import {GPT_NAMED_SIZE} from 'shared/constants';

import type {AdConfig} from 'types';

import type {ConfigReturnType} from './types';
import type {NamedSize} from 'types/google-ad';

/**
 * Find the key in an object by its associated value.
 * @param {string | number[] | null} renderedSize
 *
 * @return {string | undefined} The key associated with the given value, or undefined if not found.
 */
export function getKeyByValue(renderedSize: string | number[] | null): keyof NamedSize | undefined {
  return Object.keys(GPT_NAMED_SIZE).find((key) => {
    const objectValue = GPT_NAMED_SIZE[key as keyof NamedSize];
    if (Array.isArray(objectValue) && Array.isArray(renderedSize)) {
      return (
        objectValue.length === renderedSize.length && objectValue.every((el, index) => el === renderedSize[index])
      );
    }
    return objectValue === renderedSize;
  }) as keyof NamedSize;
}

/**
 * Retrieves configuration data for Google from the ad unit configuration.
 * @param {AdConfig} adUnitConfig The ad unit configuration containing Google configuration data.
 * @return {ConfigReturnType} An object containing the slot ID and targeting Profiles for Google.
 */
export function getGoogleConfigData(adUnitConfig: AdConfig): ConfigReturnType {
  const googleTagManager = adUnitConfig.providers?.gabriel.googleTagManager;
  const {targeting} = googleTagManager ?? meteEnvConfig.ads.gpt;
  const slotId = googleTagManager?.slotId ?? meteEnvConfig.ads.gpt.slotId;
  const eagerLoading = googleTagManager?.eagerLoading ?? meteEnvConfig.ads.gpt.eagerLoading;

  return {slotId, targeting, eagerLoading};
}
