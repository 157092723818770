import * as Sentry from '@sentry/react';

import TellyAd from 'components/Ads/Telly';
import {logger as baseLogger} from 'shared/utils/logger';

const adopplerAdGetPattern = /\[.*\]|data is undefined/;
const extraMessage = `See previos Axios error (getAdopplerAd() - error:)` as const;

const logger = baseLogger.child({tag: '[Sentry Error Boundary]'});

const withSentryErrorBoundary = (
  component: JSX.Element,
  {handleError, key}: {key: string, handleError: () => void},
) => (
  function() {
    return (
      <Sentry.ErrorBoundary
        key={key}
        beforeCapture={(scope, error) => {
          // Method calls only if error been detected.
          if (error && adopplerAdGetPattern.test(error.message)) {
            scope.setExtra(`${error.message}`, extraMessage);
          }
        }}
        onError={(error: Error) => {
          handleError();
          logger.error('Sentry ErrorBoundary error', error);
        }}
        fallback={<TellyAd />}
      >
        {component}
      </Sentry.ErrorBoundary>

    );
  })();

export default withSentryErrorBoundary;
